import type { JSX } from 'react';
import styles from './card-grid.module.css';

interface CardGridContainerProps {
  children: React.ReactNode;
}

export function CardGridContainer({
  children,
}: CardGridContainerProps): JSX.Element {
  return <div className={styles.container}>{children}</div>;
}

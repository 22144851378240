export type ReleasePhase = 'alpha' | 'private beta' | 'beta';

export function getReleasePhaseText(releasePhase: ReleasePhase): string {
  switch (releasePhase) {
    case 'alpha':
      return 'Alpha';
    case 'private beta':
      return 'Private Beta';
    case 'beta':
      return 'Beta';
    default:
      return '';
  }
}

export function truncateText(text: string): string {
  if (text.length <= 73) {
    return text;
  }
  return `${text.slice(0, 73)}`;
}

'use client';
import { clsx } from 'clsx';
import { type ReactNode, useMemo, type JSX } from 'react';
import { CodeBlockServer, CodeBlock } from 'geist/components';
import { usePathname } from 'next/navigation';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import { useSwitcherContext, useSwitcherIdContext } from '../switchers/context';
import { languages, frameworks, packageManagers } from '../switchers/data';
import styles from './code.module.css';

export { SwitcherIdProvider } from '../switchers/context';

export interface CodeProps {
  children: ReactNode;
  className?: string;
  filename?: string;
  framework?: string;
  custom?: boolean;
  padding?: boolean;
  provider?: boolean;
  ['package-manager']?: string;
  ['data-language']?: string;
  ['vercel-cli']?: boolean;
}

const formatLang = (lang?: string): string => {
  if (!lang) return '';

  switch (lang) {
    case 'jsx':
      return 'js';
    case 'tsx':
      return 'ts';
    default:
      return lang;
  }
};

/**
 * The component that renders code blocks. Can be used in mdx files with three backticks, or imported and used as a component passing the code as children.
 *
 * To make the code block framework aware, pass the framework name as a prop using the `framework` prop as a metastring. You can also pass a filename as a metastring using the `filename` prop. A list of supported frameworks can be found in the `frameworks` array in `apps/vercel-site/components/docs/switchers/data.ts`.
 *
 * Note that you should use `js` or `ts` as the language for the code block, and set framework=all to enable the language switcher. (to opt out, use `typescript` or `javascript`, or omit framework=all) If you make the code block framework aware you _must_ add an example for both typescript and javascript.
 *
 * Please also note that you must use <SwitchIdProvider> (see above) to assign a per-page unique ID to each code block.
 * @example
 * ```
 * ```js filename="pages/index.js" framework=nextjs
 * ```
 * The metadata is passed from the mdx file to the component through a custom function `parseMeta` passed in rehypePlugins of createMDX
 */
export function Code({
  children,
  className,
  filename,
  custom,
  framework,
  provider,
  'package-manager': packageManagerProp,
  'data-language': shikiLang,
  'vercel-cli': vercelCLI,
}: CodeProps): JSX.Element | null {
  const {
    framework: fw,
    language,
    packageManager,
    toggleLanguage,
    toggleFramework,
    togglePackageManager,
  } = useSwitcherContext();
  const lang = shikiLang;
  const pathname = usePathname();
  const languageMatch = formatLang(lang) === language.slug;
  const frameworkMatch = framework && framework === fw.slug;
  const supportsAllFrameworks = framework && framework === 'all';
  const packageManagerMatch =
    packageManagerProp && packageManagerProp === packageManager.slug;
  const noFrameworkSet = !framework;

  let filteredFrameworks = useMemo(() => {
    const filtered = frameworks.filter((f) =>
      f.supportedFeatures?.some((feature) => pathname.includes(feature)),
    );
    const mapped = filtered.map((f) => ({
      value: f.slug,
      label: f.name,
    }));
    return mapped;
  }, [pathname]);
  const id = useSwitcherIdContext();

  function trackCopyCode(): void {
    analytics.track(AnalyticsEvent.DOC_TRACK_COPY_CODE, {
      path: pathname || '',
      language: language.name,
      framework: fw.name,
      packageManager: packageManager.name,
      filename: filename || '',
    });
  }

  if (provider && Boolean(frameworkMatch) && language.slug === 'ts') {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const content = (children ?? '').toString() as string;

    // Hacky way to remove the node example from the list of frameworks for AI Marketplace docs
    if (id === 'page-examples-no-node') {
      filteredFrameworks = filteredFrameworks.filter(
        (f) => f.value !== 'other',
      );
    }

    return (
      <CodeBlock
        className={className}
        filename={filename}
        language={language.slug}
        tabs={{
          id,
          value: fw.slug,
          onChange: toggleFramework,
          options: filteredFrameworks,
        }}
      >
        {content}
      </CodeBlock>
    );
  }

  if (Boolean(frameworkMatch) && languageMatch) {
    return (
      <CodeBlockServer
        className={className}
        custom={custom}
        filename={filename}
        switcher={{
          options: languages.map((l) => ({ value: l.name, label: l.name })),
          value: language.name,
          onChange: toggleLanguage,
        }}
        tabs={{
          id,
          value: fw.slug,
          onChange: toggleFramework,
          options: filteredFrameworks,
        }}
        trackCopy={trackCopyCode}
      >
        {children}
      </CodeBlockServer>
    );
  }

  if (Boolean(frameworkMatch) && lang === 'json') {
    return (
      <CodeBlockServer
        className={className}
        custom={custom}
        filename={filename}
        switcher={{
          options: languages.map((l) => ({ value: l.name, label: l.name })),
          value: language.name,
          onChange: toggleLanguage,
        }}
        tabs={{
          id,
          value: fw.slug,
          onChange: toggleFramework,
          options: filteredFrameworks,
        }}
        trackCopy={trackCopyCode}
      >
        {children}
      </CodeBlockServer>
    );
  }

  if (Boolean(packageManagerMatch) && supportsAllFrameworks) {
    return (
      <CodeBlockServer
        className={className}
        custom={custom}
        tabs={{
          id,
          value: packageManager.slug,
          onChange: togglePackageManager,
          options: packageManagers.map((pm) => ({
            value: pm.slug,
            label: pm.name,
          })),
        }}
        trackCopy={trackCopyCode}
      >
        {children}
      </CodeBlockServer>
    );
  }

  if (vercelCLI && supportsAllFrameworks) {
    return (
      <CodeBlockServer className={className} trackCopy={trackCopyCode}>
        {children}
      </CodeBlockServer>
    );
  }

  if (Boolean(supportsAllFrameworks) && languageMatch) {
    return (
      <CodeBlockServer
        className={className}
        custom={custom}
        filename={filename}
        switcher={{
          options: languages.map((l) => ({ value: l.name, label: l.name })),
          value: language.name,
          onChange: toggleLanguage,
        }}
        trackCopy={trackCopyCode}
      >
        {children}
      </CodeBlockServer>
    );
  }

  //This is added to handle the case of inline code since this cannot be detected at the classname level since MDX version 2 does not include the inline classname for inlinecode
  if (noFrameworkSet && !filename && !lang) {
    return <InlineCode>{children}</InlineCode>;
  }

  if (noFrameworkSet) {
    return (
      <CodeBlockServer filename={filename} trackCopy={trackCopyCode}>
        {children}
      </CodeBlockServer>
    );
  }

  return null;
}

interface InlineCodeProps {
  children: ReactNode;
  color?: string;
  noWrap?: boolean;
  disabled?: boolean;
  title?: string;
}

export function InlineCode({
  children,
  noWrap,
  disabled,
  color,
  title,
}: InlineCodeProps): JSX.Element {
  return (
    <code
      className={clsx(styles.code, styles.inlineCode, {
        [styles.noWrap]: noWrap,
        [styles.disabled]: disabled,
      })}
      style={color ? { color } : undefined}
      title={title}
    >
      {children}
    </code>
  );
}

export function RequestHeader(props: InlineCodeProps): JSX.Element {
  return <InlineCode color="#0076FF" noWrap {...props} />;
}

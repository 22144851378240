import Image from 'next/image';
import { ArrowUpRight } from 'geist/icons';
import type { AnalyticsEventProperties } from '@vercel/site-analytics/vercel-client';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import styles from './card-grid.module.css';

interface ImageLinkProps {
  href: string;
  srcLight: string;
  srcDark: string;
  width: number;
  height: number;
  alt: string;
  context?: {
    title: string;
    description: string;
  };
  tracking?: {
    eventName: AnalyticsEventProperties[AnalyticsEvent['CLICK_EVENT']]['click_name'];
    eventValue: AnalyticsEventProperties[AnalyticsEvent['CLICK_EVENT']]['click_value'];
  };
}

export function ImageLinkCard({
  alt,
  srcLight,
  srcDark,
  href,
  context,
  width,
  height,
  tracking,
}: ImageLinkProps): JSX.Element {
  return (
    <Link
      className={styles.imageLinkCard}
      href={href}
      key={href}
      onClick={(): void => {
        if (!tracking) return;
        analytics.track(AnalyticsEvent.CLICK_EVENT, {
          click_name: tracking.eventName,
          click_value: tracking.eventValue,
        });
      }}
    >
      <div className={styles.imageLinkCardText}>
        {context ? (
          <p className={styles.imageLinkCardTitle}>
            {context.title}
            <span className={styles.imageLinkCardArrow}>
              <ArrowUpRight color="var(--geist-foreground)" size={20} />
            </span>
          </p>
        ) : null}
        {context ? (
          <p className={styles.imageLinkCardDescription}>
            {context.description}
          </p>
        ) : null}
      </div>
      <figure className={styles.figure}>
        <div className={styles.lightImage}>
          <Image alt={alt} height={height} src={srcLight} width={width} />
          <div className={styles.imageScrim} />
        </div>
        <div className={styles.darkImage}>
          <Image alt={alt} height={height} src={srcDark} width={width} />
          <div className={styles.imageScrim} />
        </div>
      </figure>
    </Link>
  );
}

'use client';
import React from 'react';
import Image from 'next/image';
import { clsx } from 'clsx';
import styles from './image-figure.module.css';

type ImageProps = Parameters<typeof Image>[0];
type Props = ImageProps & {
  caption?: string;
  margin?: number;
  captionSpacing?: number;
  shadow?: boolean;
  borderRadius?: boolean;
};

export function ImageFigure(props: Props): React.ReactNode {
  const {
    caption,
    margin = 40,
    captionSpacing = null,
    shadow = false,
    borderRadius = false,
    ...rest
  } = props;

  return (
    <figure
      className={clsx(
        styles.figure,
        shadow && styles.shadow,
        borderRadius && styles.borderRadius,
      )}
      style={{ margin: `${margin}px 0` }}
    >
      <Image {...rest} />
      {caption ? (
        <figcaption
          className={styles.figcaption}
          style={captionSpacing ? { marginTop: captionSpacing } : {}}
        >
          {caption}
        </figcaption>
      ) : null}
    </figure>
  );
}

import { ExternalLink } from 'geist/icons';
import type { DataTestId } from '@vercel/geist-test-utils';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import { ButtonLink } from '@pyra/multi-zone/link';
import type { ReleasePhase } from './lib';
import { getReleasePhaseText } from './lib';
import styles from './card-grid.module.css';

interface CallToAction {
  label: string;
  href: string;
}

interface CardProps {
  title: string;
  description: string;
  callToAction: CallToAction;
  secondaryCallToAction?: CallToAction;
  icon?: React.ReactNode;
  external?: boolean;
  releasePhase?: ReleasePhase;
  dataTestId?: DataTestId;
}

export function Card({
  title,
  description,
  callToAction,
  secondaryCallToAction,
  icon,
  external,
  releasePhase,
  dataTestId,
}: CardProps): JSX.Element {
  return (
    <div className={styles.card}>
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      <div className={styles.content}>
        <h3 className={styles.title}>
          {title}
          {releasePhase ? (
            <span className={styles.releasePhaseBadge}>
              {getReleasePhaseText(releasePhase)}
            </span>
          ) : null}
          {external ? (
            <span className={styles.external}>
              <ExternalLink size={16} />
            </span>
          ) : null}
        </h3>
        <p className={styles.description}>{description}</p>
        <div
          className={styles.callToActionContainer}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <ButtonLink
            className={styles.callToAction}
            data-testid={dataTestId}
            href={callToAction.href}
            onClick={() => {
              analytics.track(AnalyticsEvent.DOC_CARD_PRIMARY_CTA, {
                label: callToAction.label,
                href: callToAction.href,
              });
            }}
            target="_blank"
          >
            {callToAction.label}
          </ButtonLink>
          {secondaryCallToAction ? (
            <ButtonLink
              className={styles.callToAction}
              href={secondaryCallToAction.href}
              onClick={() => {
                analytics.track(AnalyticsEvent.DOC_CARD_SECONDARY_CTA, {
                  label: callToAction.label,
                  href: callToAction.href,
                });
              }}
              target="_blank"
              type="secondary"
            >
              {secondaryCallToAction.label}
            </ButtonLink>
          ) : null}
        </div>
      </div>
    </div>
  );
}

'use client';

import type { MouseEventHandler, JSX } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Cross } from 'geist/new-icons/16';
import { Button } from 'geist/components';

const SPEED = 125;

const flip = (
  start: Element,
  finish: Element,
  revert = false,
): Promise<Animation> => {
  const origin = start.getBoundingClientRect();
  const destination = finish.getBoundingClientRect();
  const delta = {
    x: origin.left - destination.left,
    y: origin.top - destination.top,
    w: origin.width / destination.width,
    h: origin.height / destination.height,
  };

  start.setAttribute('style', 'visibility: hidden;');

  const anim = finish.animate(
    revert
      ? [
          {
            transformOrigin: 'top left',
            translate: `${delta.x}px ${delta.y}px`,
            scale: `${delta.w} ${delta.h}`,
          },
        ]
      : [
          {
            transformOrigin: 'top left',
            translate: `${delta.x}px ${delta.y}px`,
            scale: `${delta.w} ${delta.h}`,
          },
          {
            transformOrigin: 'top left',
            translate: 'none',
            scale: 1,
          },
        ],
    {
      duration: window.matchMedia('(prefers-reduced-motion: no-preference)')
        .matches
        ? SPEED
        : 0,
      easing: 'ease',
      fill: revert ? 'none' : 'both',
    },
  );
  return anim.finished;
};

export function LightBox({
  children,
  portrait,
}: {
  children?: React.ReactNode;
  portrait?: boolean;
}): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const boxedRef = useRef<HTMLImageElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const toggle = useCallback((): void => setOpen((state) => !state), []);

  const handleOffClick = useCallback(
    (event: MouseEvent): void => {
      if (
        !(event.target as HTMLElement).closest('figure') &&
        !(event.target as HTMLElement).closest('button')
      ) {
        toggle();
      }
    },
    [toggle],
  );

  const handleKey = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') toggle();
    },
    [toggle],
  );

  useEffect(() => {
    const originImg =
      contentRef.current?.querySelector(
        document.documentElement.matches('.light-theme')
          ? 'img'
          : 'img:last-of-type',
      ) || undefined;
    const destinationImg =
      boxedRef.current?.querySelector(
        document.documentElement.matches('.light-theme')
          ? 'img'
          : 'img:last-of-type',
      ) || undefined;
    const originCaption =
      contentRef.current?.querySelector('figcaption') || undefined;
    const destinationCaption =
      boxedRef.current?.querySelector('figcaption') || undefined;
    if (!originImg || !destinationImg) return;
    if (open) {
      dialogRef.current?.showModal();
      Promise.all([
        dialogRef.current?.animate(
          [{ background: '#0000' }, { background: 'var(--ds-background-100)' }],
          window.matchMedia('(prefers-reduced-motion: no-preference)').matches
            ? SPEED
            : 0,
        ),
        flip(originImg, destinationImg),
        originCaption && destinationCaption
          ? flip(originCaption, destinationCaption)
          : true,
      ])
        .then(() => {
          window.addEventListener('keyup', handleKey, { once: true });
          window.addEventListener('scroll', toggle, { once: true });
          window.addEventListener('resize', toggle, { once: true });
          window.addEventListener('pointerup', handleOffClick);
        })
        .catch((error) => Error(error as string));
    } else {
      Promise.all([
        dialogRef.current?.animate(
          [{ background: '#0000' }],
          window.matchMedia('(prefers-reduced-motion: no-preference)').matches
            ? SPEED
            : 0,
        ),
        flip(originImg, destinationImg, true),
        originCaption && destinationCaption
          ? flip(originCaption, destinationCaption, true)
          : true,
      ])
        .then(() => {
          originImg.removeAttribute('style');
          originCaption?.removeAttribute('style');
          dialogRef.current?.close();
        })
        .catch((error) => Error(error as string));
    }
  }, [open, handleKey, handleOffClick, toggle]);

  const handleToggle: MouseEventHandler<HTMLDivElement> = (event): void => {
    if ((event.target as HTMLElement).tagName === 'IMG') toggle();
  };

  const onClose = (): void => {
    window.removeEventListener('keyup', handleKey);
    window.removeEventListener('scroll', toggle);
    window.removeEventListener('resize', toggle);
    window.removeEventListener('pointerup', handleOffClick);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="relative w-full rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-8 focus-within:outline-[var(--ds-focus-color)] [&_img]:cursor-zoom-in"
        onClick={handleToggle}
        ref={contentRef}
      >
        {children}
        <button className="sr-only" onClick={toggle} type="button">
          Zoom Image
        </button>
      </div>
      <dialog
        aria-modal="true"
        className="group inset-0 m-0 h-full max-h-full w-full max-w-full cursor-zoom-out border-0 p-0 backdrop:opacity-0"
        onClose={onClose}
        ref={dialogRef}
      >
        <div className="grid h-full w-full place-items-center content-center">
          <div className="absolute right-4 top-4 opacity-0 transition duration-200 group-[[open]]:opacity-100">
            <Button
              aria-label="Close Lightbox"
              onClick={toggle}
              shape="square"
              size="large"
              svgOnly
              type="tertiary"
              typeName="button"
            >
              <Cross className="pointer-events-none" />
            </Button>
          </div>
          <div
            className={`${portrait ? 'h-[75vmin] [&_figure]:h-full [&_figure_img]:h-full [&_figure_img]:w-auto [&_figure_img]:flex-1' : 'w-[75vmin]'} cursor-default`}
            ref={boxedRef}
          >
            {children}
          </div>
        </div>
      </dialog>
    </>
  );
}

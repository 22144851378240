'use client';

import { useCallback, useMemo } from 'react';
import { clsx } from 'clsx';
import type { CSSProperties } from 'react';
import { CheckCircle } from '../../new-icons/16/check-circle';
import { Information } from '../../new-icons/16/information';
import { Stop } from '../../new-icons/16/stop';
import { Warning } from '../../new-icons/16/warning';
import type { Size, UseTypeProps } from '../../utils';
import { useType } from '../../utils';
import styles from './note.module.css';

interface NoteProps extends UseTypeProps {
  label?: string | false;
  small?: boolean;
  center?: boolean;
  className?: string;
  size?: Size;
  disabled?: boolean;
  action?: React.ReactNode;
  children: React.ReactNode;
  style?: CSSProperties;
  id?: string;
  icon?: React.ReactNode;
}

/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
const Note: React.FC<NoteProps> = ({
  children,
  className,
  type,
  fill,
  label,
  size,
  center,
  variant,
  action,
  style,
  disabled,
  icon,
  ...props
}) => {
  const typeCn = useType(type, fill, variant);

  if (Array.isArray(typeCn)) {
    typeCn.shift();
  }

  const labelGap = useMemo(() => {
    if (label) return 4;
    if (size !== undefined) return SizeToGap[size];

    return 12;
  }, [label, size]);

  const Label = useCallback(() => {
    if (label === false) return null;

    if (label) {
      return (
        <span
          style={{
            whiteSpace: 'nowrap',
            fontWeight: 600,
          }}
        >
          {label}:{' '}
        </span>
      );
    }

    return (
      <span
        style={{
          display: 'flex',
          height: 16,
        }}
      >
        <GetIcon icon={icon} type={type} />
      </span>
    );
  }, [label, icon, type]);

  return (
    <div
      className={clsx(
        styles.note,
        className,
        typeCn,
        {
          [String(styles.action)]: action,
          [String(styles.small)]: size === 'small',
          [String(styles.large)]: size === 'large',
          [String(styles.center)]: center,
          [String(styles.disabled)]: disabled,
        },
        'v3-colors',
      )}
      {...props}
      data-geist-note=""
      data-version="v1"
      style={style}
    >
      <div
        className={clsx(styles.content, label ? styles.hasLabel : '')}
        style={{
          gap: labelGap,
        }}
      >
        <Label />
        <span>{children}</span>
      </div>
      {action ? <div>{action}</div> : null}
    </div>
  );
};

function GetIcon({
  icon,
  type,
}: {
  icon?: React.ReactNode;
  type?: string;
}): React.ReactNode {
  if (icon) {
    return icon;
  }

  if (type === 'success') {
    return <CheckCircle color="var(--ds-success-900)" size={16} />;
  }

  if (type === 'error') {
    return <Stop color="var(--ds-red-900)" size={16} />;
  }

  if (type === 'warning') {
    return <Warning color="var(--ds-amber-900)" size={16} />;
  }

  return <Information color="currentColor" />;
}

export { Note };

const SizeToGap: Record<Size, number> = {
  xSmall: 8,
  small: 8,
  mediumSmall: 8,
  large: 12,
};

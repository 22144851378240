'use client';
import { usePathname } from 'next/navigation';
import { Badge } from 'geist/components';
import type { JSX } from 'react';
import { Breadcrumbs } from '#/app/components/layout/navbar/breadcrumbs';
import styles from './page-info.module.css';

export function PageInfo({
  handleIndexClick,
  contentType,
}: {
  handleIndexClick?: () => void;
  contentType?: string;
}): JSX.Element {
  const path = usePathname() as string;
  function contentBadge(type: string): JSX.Element {
    switch (type) {
      case 'Reference':
        return (
          <Badge className={styles.noCapitalize} variant="teal-subtle">
            {type}
          </Badge>
        );
      case 'Tutorial':
        return (
          <Badge className={styles.noCapitalize} variant="blue-subtle">
            {type}
          </Badge>
        );
      case 'Conceptual':
        return (
          <Badge className={styles.noCapitalize} variant="green-subtle">
            {type}
          </Badge>
        );
      case 'How-to':
        return (
          <Badge className={styles.noCapitalize} variant="purple-subtle">
            {type}
          </Badge>
        );
      default:
        return (
          <Badge className={styles.noCapitalize} variant="blue-subtle">
            {type}
          </Badge>
        );
    }
  }
  return (
    <div className={styles.container}>
      <Breadcrumbs handleIndexClick={handleIndexClick} path={path} />
      <div className={styles.badgeAndReadTime}>
        {contentType ? contentBadge(contentType) : null}
      </div>
    </div>
  );
}

import { clsx } from 'clsx';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import styles from './quickstart.module.css';

export function QuickStartTextCard({
  href,
  title,
  description,
  icon,
}: {
  href: string;
  title: string;
  description: string;
  icon?: React.ReactNode;
}): JSX.Element {
  return (
    <div className={styles.quickstartBox}>
      <Link href={href}>
        <div className={clsx(styles.quickstart, styles.quickstartText)}>
          {icon ? <div className={styles.quickstartIcon}>{icon}</div> : null}
          <div className={styles.content}>
            <b>{title}</b>
            <span className={styles.note}>{description}</span>
          </div>
        </div>
      </Link>
    </div>
  );
}

'use client';
import type { JSX } from 'react';
import { InlineCode } from '#/app/components/text/code';
import { useSwitcherContext } from './context';

interface ExtensionProps {
  filename: string;
  // This prop makes it `.jsx` or `.tsx`
  endsWithX?: boolean;
  // This prop makes it `.mjs` instead of `.js`
  mjs?: boolean;
}

export function PreferredExtension({
  filename,
  endsWithX,
  mjs,
}: ExtensionProps): JSX.Element {
  const { language } = useSwitcherContext();
  let extension = endsWithX ? `${language.slug}x` : language.slug;
  if (extension.includes('js') && mjs) extension = 'mjs';
  return (
    <InlineCode>
      {filename}.{extension}
    </InlineCode>
  );
}

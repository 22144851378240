'use client';
import { usePathname } from 'next/navigation';
import { ChevronRight, ChevronLeft } from 'geist/icons';
import { tid } from '@vercel/geist-test-utils';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import {
  getSecondaryNavigation,
  flattenNavData,
  trimPath,
} from '@pyra/docs-shared/data/navigation/utils';
import styles from './prev-next-nav.module.css';

export function PrevNextNav(): JSX.Element {
  const path = usePathname() as string;
  const secondaryNavigation = getSecondaryNavigation(primaryNavigation, path);
  const flattenNav = secondaryNavigation.currentItem?.key
    ? flattenNavData(secondaryNavigation.currentItem.key, primaryNavigation)
    : [];
  const currentItemIndex = flattenNav.findIndex(
    (item) => trimPath(item.href) === trimPath(path),
  );
  let nextItem = { name: '', href: '' };
  let previousItem = { name: '', href: '' };
  if (currentItemIndex > 0) {
    previousItem = {
      name: flattenNav[currentItemIndex - 1].name,
      href: flattenNav[currentItemIndex - 1].href,
    };
  }
  if (currentItemIndex < flattenNav.length - 1) {
    nextItem = {
      name: flattenNav[currentItemIndex + 1].name,
      href: flattenNav[currentItemIndex + 1].href,
    };
  }

  return (
    <>
      {/* eslint-disable-next-line  @typescript-eslint/prefer-optional-chain */}
      {secondaryNavigation.currentItem &&
      secondaryNavigation.currentItem.items &&
      secondaryNavigation.currentItem.items.length > 0 ? (
        <div className={styles.container}>
          {previousItem.name !== '' && (
            <Link
              className={styles.link}
              data-testid={tid('bottomnav', 'previous')}
              href={previousItem.href}
            >
              <div className={styles.navIcon}>
                <ChevronLeft className={styles.icon} size={20} />
              </div>
              <div className={styles.previous}>
                <div className={styles.label}>Previous</div>
                <div className={styles.title}>{previousItem.name}</div>
              </div>
            </Link>
          )}
          <div className={styles.separator} />
          {nextItem.name !== '' && (
            <Link
              className={styles.link}
              data-testid={tid('bottomnav', 'next')}
              href={nextItem.href}
            >
              <div className={styles.next}>
                <div className={styles.label}>Next</div>
                <div className={styles.title}>{nextItem.name}</div>
              </div>
              <div className={styles.navIcon}>
                <ChevronRight className={styles.icon} size={20} />
              </div>
            </Link>
          )}
        </div>
      ) : null}
    </>
  );
}

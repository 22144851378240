'use client';

import type { PropsWithoutRef, ReactNode, JSX } from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import { useSwitcherContext } from './context';
import type { Slug } from './data';
import { slugs } from './data';

interface CustomContentProps {
  children: ReactNode;
  framework?: string[];
  languages?: string[] | undefined;
}

function slugMatchesArray(slug: Slug, list: string[]): boolean {
  return slugs.some((s) => s === slug && list.includes(s));
}

/**
 * Custom content to go with framework chosen on the codeblock header.
 * The content can be displayed anywhere on the page so long as the framework supports the feature
 * @example
 * ```
 * <CustomContent framework={['nextjs', 'nuxtjs']}>
 * some content for nextjs and nuxtjs
 * </CustomContent>
 * ```
 * or single framework
 * ```
 * <CustomContent framework={['nextjs']}>
 * some content for nextjs
 * </CustomContent>
 * ```
 */
export function CustomContent({
  children,
  framework,
  languages,
  ...props
}: CustomContentProps): JSX.Element | null {
  const { framework: fw, language: selectedLanguage } = useSwitcherContext();
  /**
   * If a lang is selected but doesn't match
   * the allowed languages, don't show the content
   */
  if (
    languages?.length &&
    languages.length > 0 &&
    !slugMatchesArray(selectedLanguage.slug, languages)
  ) {
    return null;
  }

  if (!framework && !languages) {
    return null;
  }

  const shouldShowFW = !framework
    ? false
    : slugMatchesArray(fw.slug, framework);
  const shouldShowLang = !languages
    ? false
    : slugMatchesArray(selectedLanguage.slug, languages);
  if (!shouldShowLang && !shouldShowFW) return null;

  return (
    <span>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return null;
        return cloneElement(child, {
          ...child.props,
          ...props,
        } as PropsWithoutRef<typeof child.type>);
      })}
    </span>
  );
}

/* TODO: This file uses styled jsx. If it's converted to CSS modules it may not need this directive. */
'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { QuestionCircle as IconQuestionCircle } from 'geist/icons';
import styles from './helpLink.module.css';

export function HelpLink({ children, href, hasIcon, nested, ...props }) {
  return (
    <a
      href={href}
      {...props}
      className={clsx(
        { [styles.aIcon]: hasIcon, [styles.aNested]: nested },
        styles.a,
      )}
    >
      <span className={styles.aIconSpan}>{children}</span>
      {hasIcon ? <IconQuestionCircle size={15} /> : null}
    </a>
  );
}

HelpLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hasIcon: PropTypes.bool,
  href: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default HelpLink;

import { ExternalLink } from 'geist/icons';
import type { CSSProperties, JSX } from 'react';
import type { AnalyticsEventProperties } from '@vercel/site-analytics/vercel-client';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import { Link } from '@pyra/multi-zone/link';
import styles from './card-grid.module.css';
import type { ReleasePhase } from './lib';
import { getReleasePhaseText } from './lib';

interface CardProps {
  title: string;
  description: string;
  href: string;
  icon?: React.ReactNode;
  external?: boolean;
  releasePhase?: ReleasePhase;
  tracking?: {
    eventName: AnalyticsEventProperties[AnalyticsEvent['CLICK_EVENT']]['click_name'];
    eventValue: AnalyticsEventProperties[AnalyticsEvent['CLICK_EVENT']]['click_value'];
  };
  customBadgeText?: string;
  customBadgeStyles?: CSSProperties;
  footer?: React.ReactNode;
}

export function LinkCard({
  title,
  description,
  href,
  icon,
  external,
  releasePhase,
  tracking,
  customBadgeText,
  customBadgeStyles,
  footer,
}: CardProps): JSX.Element {
  return (
    <Link
      className={styles.card}
      href={href}
      onClick={(): void => {
        if (!tracking) return;
        analytics.track(AnalyticsEvent.CLICK_EVENT, {
          click_name: tracking.eventName,
          click_value: tracking.eventValue,
        });
      }}
    >
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      <div className={styles.content}>
        <h3 className={styles.title}>
          {title}
          {customBadgeText || releasePhase ? (
            <span
              className={styles.releasePhaseBadge}
              style={customBadgeStyles}
            >
              {releasePhase
                ? getReleasePhaseText(releasePhase)
                : customBadgeText}
            </span>
          ) : null}
          {external ? (
            <span className={styles.external}>
              <ExternalLink size={16} />
            </span>
          ) : null}
        </h3>
        <p className={styles.description}>{description}</p>
        {footer ? <div className={styles.footer}>{footer}</div> : null}
      </div>
    </Link>
  );
}

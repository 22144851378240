'use client';
import type { JSX } from 'react';
import type { TableCellType, TableHeadCellType } from '../../types';

export function TableRowCell(props: TableCellType): JSX.Element {
  return <td>{props.children}</td>;
}

export function TableHeadCell(props: TableHeadCellType): JSX.Element {
  return <th>{props.children}</th>;
}

import { ChevronRight } from 'geist/icons';
import { tid } from '@vercel/geist-test-utils';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import { getNavParents } from '@pyra/docs-shared/data/navigation/utils';
import type { SideBarItem } from '@pyra/docs-shared/data/navigation/types';
import styles from './breadcrumbs.module.css';

export function Breadcrumbs({
  handleIndexClick,
  path,
}: {
  handleIndexClick: (() => void) | undefined;
  path: string;
}): JSX.Element {
  const breadcrumbs = getNavParents(
    path,
    primaryNavigation[0].posts as SideBarItem[],
  );

  const maxCrumbWidth = breadcrumbs && breadcrumbs.length > 3 ? 110 : 200;

  const shouldApplyEllipsis = breadcrumbs && breadcrumbs.length > 3;

  return (
    <>
      {Boolean(breadcrumbs) &&
        breadcrumbs?.length !== undefined &&
        breadcrumbs.length > 1 && (
          <div className={styles.breadCrumbsContainer}>
            {breadcrumbs.map((item, i) => (
              <div key={item.name}>
                {item.href === '' ? (
                  <span
                    className={styles.crumb}
                    style={{ maxWidth: `${maxCrumbWidth}px` }}
                  >
                    {item.name}
                  </span>
                ) : (
                  <div className={styles.crumbContainer}>
                    <Link
                      className={`${styles.crumb} ${
                        shouldApplyEllipsis ? styles.crumbEllipsis : ''
                      }`}
                      data-testid={tid('breadcrumb', i.toString())}
                      href={item.href}
                      onClick={handleIndexClick}
                      style={{ maxWidth: `${maxCrumbWidth}px` }}
                    >
                      {item.name}
                    </Link>
                    <span className={styles.icon}>
                      <ChevronRight size={16} />
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
    </>
  );
}
